<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="row">
      <div class="col-md-12">
        <card type="contributions card-plain">
          <div class="row">
            <div class="col-md-8">
              <h1 class="card-title">
                <div class="card-category"><b>Program name</b></div>
                {{ this.model.name }}
              </h1>
              <h3 class="card-category">
                <b>Program ID</b> <br />
                {{ this.programID }}<br />
                <br />
                <b>Duration</b>
                <br />
                {{ this.model.start.toDate() | moment("MMM, DD YYYY") }} -
                {{ this.model.end.toDate() | moment("MMM, DD YYYY") }}
              </h3>
              <p class="card-category">
                <b>Program description</b> <br />
                {{ this.model.description }}
              </p>
              <div v-if="model.partners != null && model.partners.length > 0">
                <h2 class="card-category">
                  <b>Partners</b>
                </h2>
                <div class="row margin-left">
                  <div v-for="item in this.model.partners" :key="item.id">
                    <div v-if="type != 3">
                      <router-link v-bind:to="{
                        name: 'Partners-Detail',
                        params: { partner_id: item.id },
                      }">
                        <base-button type="danger" simple>{{
                          item.name
                        }}</base-button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="type < 3" class="col justify-content-end d-flex p-0">
              <router-link :to="{
                name: 'Program-Edit',
                params: {
                  program_id: $route.params.program_id,
                },
              }">
                <button class="btn btn-outline-info btn-sm w-100 mb-2 d-flex justify-content-around align-items-center">
                  <i class="lni lni-pencil-1 mr-2"></i>
                  Edit program
                </button>
              </router-link>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <card class="card-plain">
          <tabs type="primary" square>
            <tab-pane label="Startups">
              <div class="row">
                <div v-if="type < 3" class="col-auto ml-auto mb-2">
                  <router-link :to="{
                    name: 'Invite User',
                    params: {
                      program_id: $route.params.program_id,
                      user_type: 'startup',
                    },
                  }">
                    <base-button class="btn-block" type="danger">
                      <i class="fas fa-plus"></i>
                      Invite startup</base-button>
                  </router-link>
                </div>
                <card class="col-sm-12" v-if="model.startups.length > 0">
                  <el-table :data="model.startups">
                    <el-table-column min-width="50" label="" align="center">
                      <div class="photo" slot-scope="{ row }">
                        <img :src="row.image ? row.image : 'img/placeholder.jpg'" alt="Table image" />
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" label="Name" sortable property="name">
                      <div slot-scope="{ row }">
                        <router-link v-if="row.id" :to="{
                          name: 'Startup information',
                          params: {
                            startup_id: row.id,
                            program_id: $route.params.program_id,
                            startup: row,
                            programDetails: {
                              start: model.start,
                              end: model.end,
                              name: model.name,
                              performanceDataPoints:
                                model.performanceDataPoints,
                              additionalQuestions: model.additionalQuestions,
                            },
                          },
                        }">
                          {{ row.name }}
                          <!-- {{row.id}} -->
                        </router-link>
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" label="Type" sortable property="sector">
                    </el-table-column>
                    <el-table-column min-width="100" label="Employees">
                      <div slot-scope="{ row }">
                        {{
                          parseInt(row.employees.female) +
                          parseInt(row.employees.male)
                        }}
                      </div>
                    </el-table-column>
                    <el-table-column min-width="200" label="Progress" sortable property="progress">
                      <div slot-scope="{ row }">
                        <base-progress label="Progress" value-position="right" type="danger" :value="row.progress" />
                      </div>
                    </el-table-column>
                  </el-table>
                </card>
                <div class="d-flex col-12 justify-content-center"
                  v-if="model.startups != null && model.startups.length == 0">
                  <h4 class="text-center alert alert-light col-8">
                    No startups found. <br />
                    <div v-if="type < 4">
                      Invite a startup to this program by clicking
                      <router-link :to="{
                        name: 'Invite User',
                        params: {
                          program_id: $route.params.program_id,
                          user_type: 'startup',
                        },
                      }">here</router-link>.
                    </div>
                  </h4>
                </div>
              </div>
            </tab-pane>
            <tab-pane label="Incubation Managers" v-if="type < 4">
              <div class="row">
                <div v-if="type < 3" class="col-auto ml-auto mb-2">
                  <router-link :to="{
                    name: 'Invite User',
                    params: {
                      program_id: $route.params.program_id,
                      user_type: 'incubation_manager',
                    },
                  }">
                    <base-button class="btn-block" type="danger">
                      <i class="fas fa-plus"></i>
                      Invite incubation manager</base-button>
                  </router-link>
                </div>
                <card class="col-sm-12" v-if="
                  incubationManagers != null && incubationManagers.length > 0
                ">
                  <el-table :data="incubationManagers">
                    <el-table-column min-width="150" label="ID" prop="userId">
                    </el-table-column>
                    <el-table-column min-width="100" label="Email" property="email" sortable>
                    </el-table-column>
                    <el-table-column min-width="200" label="type" property="type" sortable>
                    </el-table-column>
                  </el-table>
                </card>
                <div class="d-flex col-12 justify-content-center" v-if="
                  incubationManagers == null || incubationManagers.length == 0
                ">
                  <h4 class="text-center alert alert-light col-8">
                    No managers found. <br />
                    <div v-if="type < 3">
                      Please add a incubation managers to this program by
                      clicking
                      <router-link :to="{
                        name: 'Invite User',
                        params: {
                          program_id: $route.params.program_id,
                          user_type: 'incubation_manager',
                        },
                      }">here</router-link>.
                    </div>
                  </h4>
                </div>
              </div>
            </tab-pane>
            <tab-pane label="Additional questions" v-if="type < 4">
              <div class="row">
                <card class="col-sm-12">
                  <el-table :data="model.additionalQuestions" v-if="
                    model.additionalQuestions != undefined &&
                    model.additionalQuestions.length > 0
                  ">
                    <el-table-column min-width="100" label="Question name" property="question">
                    </el-table-column>
                  </el-table>
                  <div class="d-flex col-12 justify-content-center" v-else>
                    <div class="text-center alert alert-dark">
                      No additional questions are available for this program.
                    </div>
                  </div>
                  <div class="text-center alert alert-info" v-if="type < 3">
                    Click on "Edit program" to modify or add additional
                    questions for this program
                  </div>
                </card>
              </div>
            </tab-pane>
            <tab-pane label="Milestones">
              <div class="row">
                <div v-if="type < 3" class="col-auto ml-auto mb-2">
                  <router-link :to="{
                    name: 'Milestone-Add',
                    params: { program_id: $route.params.program_id },
                  }">
                    <base-button class="btn-block" type="danger">
                      <i class="fas fa-plus"></i>
                      Add program milestone</base-button>
                  </router-link>
                </div>
                <div class="card col-12">
                  <el-table header-cell-class-name="table-transparent" header-row-class-name="table-transparent"
                    row-class-name="table-transparent" :data="milestoneData">
                    <el-table-column min-width="150" sortable label="Name" property="name"></el-table-column>
                    <el-table-column min-width="200" sortable align="center" label="Description"
                      property="description"></el-table-column>
                    <el-table-column min-width="125" sortable align="center" label="Timeline" property="workdays">
                      <div slot-scope="{ row }">
                        {{ row.workdays.toDate() | moment("MMM, DD YYYY") }}
                      </div>
                    </el-table-column>
                    <el-table-column min-width="100" label="File Upload" align="center" sortable
                      property="isFileUpload">
                      <div slot-scope="{ row }">
                        <i class="tim-icons icon-check-2" v-if="row.isFileUpload" slot="on"></i>
                        <i class="tim-icons icon-simple-remove" v-if="!row.isFileUpload" slot="off"></i>
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" align="right" header-align="right" label="Tags">
                      <div slot-scope="{ row }">
                        <div v-for="tag in row.tags" :key="tag" class="but-margin">
                          <base-button type="danger" size="sm" simple>{{
                            tag
                          }}</base-button>
                        </div>
                      </div>
                    </el-table-column>
                    <el-table-column v-if="type < 3" min-width="100" header-align="right" align="right" label="Options">
                      <div slot-scope="{ row }" class="text-right table-actions">
                        <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                          <router-link :to="{
                            name: 'Program-Milestone-Edit',
                            params: {
                              milestone_id: row.id,
                              program_id: $route.params.program_id,
                            },
                          }">
                            <button class="btn btn-icon btn-fab btn-outline-info btn-sm">
                              Edit
                              <i class="lni lni-pencil-1"></i>
                            </button>
                          </router-link>
                        </el-tooltip>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
            <tab-pane label="Score points" v-if="type < 3" >
              <div class="row">
                <div class="col-auto ml-auto mb-2">

                  <router-link :to="{
                    name: 'Create-Score-Points',
                    params: {program:this.model },
                  }">
                    <base-button class="btn-block" type="danger">
                    <i class="fas fa-plus"></i>
                    Recreate Score Points</base-button>
                  </router-link>
        

                </div>
                <div class="card col-12">
                  <el-table header-cell-class-name="table-transparent" header-row-class-name="table-transparent"
                    row-class-name="table-transparent" :data="model.performanceDataPoints">
                    <el-table-column max-width="50" label="#" type="index">
                  
                    </el-table-column>
                    <el-table-column min-width="150" sortable label="Name" property="row">
                      <div slot-scope="{ row }">
                        {{ row }}
                      </div>
                    </el-table-column>
                  </el-table>
                </div>

              </div>

            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Timestamp } from "firebase/firestore";
import { TabPane, Tabs } from "src/components";
import { BaseProgress } from "src/components/index";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BaseProgress,

    TabPane,
    Tabs,
  },
  data() {
    return {
      isLoading: true,
      programID: "",
      name: null,
      phone: null,
      email: null,
      address: null,
      //program: null,
      startupData: [],
      milestoneData: [],
      incubationManagers: [],
      performanceDataMap:[],
      model: {
        name: "",
        description: "",
        start: Timestamp.now(),
        end: Timestamp.now(),
        additionalQuestions: [],
        performanceDataPoints: [],
        partners: [],
        startups: [],
        incubationManagers: [],
      },
    };
  },
  async created() {
    this.programID = this.$route.params.program_id;
    this.model = await this.getProgram(this.programID);
    this.model.startups = await this.getAllStartupsForProgram(this.programID);
    this.milestoneData = await this.getProgramMilestones(this.programID);
    if (
      this.model.incubationManagers != null &&
      this.model.incubationManagers.length > 0
    ) {
      this.incubationManagers = await this.getUsersById(
        this.model.incubationManagers
      );
    }
    if (
      this.model.additionalQuestions != undefined &&
      this.model.additionalQuestions.length > 0
    ) {
      this.model.additionalQuestions.sort(function (a, b) {
        if (a.question == undefined || b.question == undefined) {
          return 0;
        }
        if (a.question < b.question) {
          return -1;
        }
        if (a.question > b.question) {
          return 1;
        }
        return 0;
      });
    }
    if (this.model.performanceDataPoints != undefined && this.model.performanceDataPoints.length > 0) {
      this.performanceDataMap = Object.entries(this.model.performanceDataPoints);
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getProgram: "program/getProgram",
      getUsersById: "user/getUsersById",
    }),
    onImageChange(file) {
      this.images.regular = file;
    },
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
      getAllStartupsForProgram: "startup/getAllStartupsForProgram",
      getProgramMilestones: "program/getProgramMilestones",
    }),
  },
};
</script>
<style lang="scss">
.but-margin {
  margin-bottom: 6px;
}

.margin-left {
  margin-left: 1px;
}
</style>
