<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Startup Data Points"
        subTitle="Please input values that reflect the current performance of the startup (1-5)">
        <div slot="header">
          <div class="row">
            <div class="col-3 d-flex flex-column justify-items-end">
              <strong class="card-title">Evaluation Time:</strong>
              <p class="card-category">
                {{
                  model.performanceDataPointsWithValues[0].timestamp
                  | moment("MMM, DD YYYY , hh:mm:ss a")
                }}
              </p>
            </div>
            <div class="col text-right d-flex flex-column justify-items-end">
              <strong class="card-title">Evaluation By:</strong>
              <p class="card-category">
                {{ model.performanceDataPointsWithValues[0].evaluatorEmail }}
                <b>{{
                  model.performanceDataPointsWithValues[0].evaluatorType
                }}</b>
              </p>
            </div>
          </div>
        </div>
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <div>
              <div :v-if="performanceDataPoints.length > 0" class="row">
                <ValidationProvider class="col-sm-3 d-grid align-content-start" :name="dataPoint"
                  rules="required|max_value:5|min_value:0" v-slot="{ passed, failed, errors }"
                  v-for="dataPoint in performanceDataPoints" :key="dataPoint">
                  <base-input class="mt-2" required :label="dataPoint" v-model="model.performanceDataPointsWithValues[0]['data'][
                  `${dataPoint}`
                ]
                  " type="number" :placeholder="1" :error="errors[0]" :class="[
                  { 'has-success': passed },
                  { 'has-danger': failed },
                ]">
                  </base-input>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <div class="alert alert-default m-0 mt-3">
          Please input values that reflect the current performance of the
          startup (1-5).
          <br /> <strong>Note: Input a value of 0 if evaluation of data point is not available.</strong>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import {
email,
max_value,
min_value,
numeric,
required,
} from "vee-validate/dist/rules";
import { mapActions, mapGetters, mapState } from "vuex";
extend("email", email);
extend("max_value", max_value);
extend("min_value", min_value);
extend("required", required);
extend("numeric", numeric);
export default {
  props: {
    performanceDataPoints: {
      type: Array,
      description: "Additional Questions From Program",
    },
  },
  data() {
    return {
      model: {
        performanceDataPointsWithValues: [
          {
            data: null,
            timestamp: null,
            evaluatorEmail: null,
            evaluatorType: null,
          },
        ],
      },
    };
  },
  async created() {
    this.model.performanceDataPointsWithValues[0].data = Object.fromEntries(
      this.performanceDataPoints.map((key) => [key, ""])
    );
    // 
    // 
    this.updateCurrentTime();
    const user = await this.currentUser();
    if (user) {
      this.model.performanceDataPointsWithValues[0].evaluatorEmail =
        this.user.email;
      this.model.performanceDataPointsWithValues[0].evaluatorType =
        this.readableType;
    } else {
      return;
    }
  },
  methods: {
    ...mapActions({
      currentUser: "auth/currentUser",
    }),
    validate() {
      return this.$refs.form.validate().then((res) => {
        // 
        if (res) {
          this.$emit("on-validated", res, this.model);
        }
        else {
        }
      });
    },
    updateCurrentTime() {
      setTimeout(this.updateCurrentTime, 1000);
      this.model.performanceDataPointsWithValues[0].timestamp =
        new Date().getTime();
    },
    clear() {
      this.model.performanceDataPointsWithValues[0].data = Object.fromEntries(
        this.performanceDataPoints.map((key) => [key, ""])
      );
      this.model.performanceDataPointsWithValues[0].timestamp =
        new Date().getTime();
      this.$refs.form.reset();
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters({
      readableType: "auth/readableType",
    }),
  },
};
</script>
<style></style>
