<template>
  <card type="chart">
    <template slot="header">
      <h2 class="row align-items-center mb-0">
        <i class="lni lni-stats-up ml-4"></i>
        <div class="col-10 col-md-5 mt-2">
          <h3 class="card-title">Performance Data</h3>
          <h5 class="card-category">Progress (out of 5)</h5>
        </div>
        <h5 class="col-12 col-md-6 text-left text-md-right d-block pr-4"
          v-if="allPerformanceDataPointsWithValues.length > 0">
          <strong>Evaluation Time:</strong>
          <br />
          {{ performanceChart.timeStamp | moment("MMM, DD YYYY , hh:mm a") }}
          <br />
          <strong>Evaluation Version:</strong>
          {{ performanceChart.activeIndex + 1 }} of
          {{ allPerformanceDataPointsWithValues.length }}
          <br />
          <strong v-if="performanceChart.evaluatorType != null &&
            performanceChart.evaluatorEmail != null
          ">Evaluated by:</strong>
          <span v-if="showEvaluator == true">
            {{ performanceChart.evaluatorEmail }}

          </span>
          <span v-else>
            Evaluator {{ performanceChart.activeIndex + 1 }}
          </span>

          <br />
          {{ performanceChart.evaluatorType }}


        </h5>
      </h2>
    </template>
    <div class="chart-area" v-if="allPerformanceDataPointsWithValues.length > 0">
      <line-chart style="height: 100%" ref="performanceChart" :chart-data="performanceChart.chartData"
        :gradient-colors="performanceChart.gradientColors" :gradient-stops="performanceChart.gradientStops"
        :extra-options="performanceChart.extraOptions">
      </line-chart>
    </div>
    <div class="alert alert-dark" v-if="allPerformanceDataPointsWithValues.length == 0">
      No data
    </div>
    <template slot="footer">
      <div
        class="d-flex flex-column-reverse flex-lg-row justify-content-between align-items-lg-center align-items-stretch">
        <div class="d-flex flex-column flex-md-row justify-content-between">
          <button v-if="userType < 4" class="btn btn-outline-default" @click="onShowModal()">
            <i class="fas fa-plus"></i>
            Add new evaluation
          </button>
          <button v-if="userType < 3 && !isLastElement && allPerformanceDataPointsWithValues.length > 1"
            class="ml-md-3 mt-3 mt-md-0 btn btn-outline-danger d-flex align-items-center justify-content-center"
            @click="onRemoveEvaluation()">
            <i class="lni lni-trash-3 mr-2"></i>
            Remove this Evaluation
          </button>
        </div>
        <div class="justify-content-between mb-3 mb-lg-0 d-flex align-items-center btn-group align-self-md-end" 
          v-if="allPerformanceDataPointsWithValues.length > 1">
          <!--    v-for="(option, index) in performanceChartCategories"
            :key="option.name.toString()" -->
          <button class="btn btn-outline-default d-flex align-items-center" v-if="this.performanceChart.activeIndex != 0"
            @click="changeChartIndex(false)">
            <i class="fa fa-chevron-left pr-2 font-weight-bold align-items-center d-flex"></i>
            Previous
          </button>
          <button @click="changeChartIndex()" v-if="this.performanceChart.activeIndex + 1 <
            allPerformanceDataPointsWithValues.length
          " class="btn btn-outline-default d-flex align-items-center justify-content-end">
            Next
            <i class="fa fa-chevron-right pl-2 font-weight-bold align-items-center d-flex"></i>
          </button>

        </div>
      </div>

      <modal :show="showModal" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Add New Evaluation</h4>
        <data-points-step ref="addEvaluation" @on-validated="addNewEvaluation"
          :performance-data-points="this.performanceChart.chartData.labels"></data-points-step>
        <template slot="footer">
          <div class="col-12 d-flex justify-content-between py-4">
            <button class="btn btn-info" @click="onSubmitEvaluation()">
              Save evaluation </button>
            <button class="btn btn-outline-danger" @click="onCloseModal()">
              Cancel</button>
          </div>
        </template>
      </modal>

    </template>
  </card>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import * as chartConfigs from "@/components/Charts/config";
import config from "@/config";
import { Modal } from "src/components";
import DataPointsStep from "src/pages/Ice/Startups/RegisterStartup/Steps/DataPointsStep.vue";
import swal from "sweetalert2";
import { mapGetters } from "vuex";
let chartStyleConfigs = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 3,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointColor: "#fff",
  pointBorderColor: "rgba(255,255,255,0)",
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
};
export default {
  name: "performance-chart",
  components: {
    LineChart,
    Modal,
    DataPointsStep,
  },
  props: {
    performanceDataPointsWithValues: {
      description: "Performance data points",
    },
    performanceDataPoints: {},
    showEvaluatorDetail: {},
  },
  data() {
    return {
      showModal: false,
      showEvaluator: false,
      performanceChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              ...chartStyleConfigs,
              data: [],
            },
          ],
        },
        extraOptions: chartConfigs.performanceChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        timeStamp: null,
        evaluatorEmail: null,
        evaluatorType: null,
        activeIndex: 0,
      },
      allPerformanceDataPointsWithValues: [],
      performanceValuesForUpdate: [],
    };
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const dataPoints = await this.$props.performanceDataPoints;
      const data = await this.$props.performanceDataPointsWithValues;
      this.showEvaluator = await this.$props.showEvaluatorDetail;
      if (dataPoints != null && dataPoints.length > 0) {
        this.performanceChart.chartData.labels = dataPoints;
        if (data != null && data.length > 0) {

          this.performanceChart.chartData.datasets[0].data =
            this.createDataPoints(data[0].data).data;
          this.performanceChart.timeStamp = data[0].timestamp;
          this.allPerformanceDataPointsWithValues = data;
          this.performanceChart.activeIndex = data.length - 1;


          this.performanceChart.evaluatorEmail = data[0].evaluatorEmail;
          this.performanceChart.evaluatorType = data[0].evaluatorType;

        }
      }
    },
    createDataPoints(_data) {
      let labels = [];
      let data = [];
      for (const [key, value] of Object.entries(_data).sort()) {
        labels.push(key);
        data.push(value);
      }
      return {
        data: data,
        labels: labels,
      };
    },
    onSubmitEvaluation() {
      this.$refs.addEvaluation.validate();
    },
    changeChartIndex(increment = true) {
      let allDataPoints = this.allPerformanceDataPointsWithValues;
      let activeIndex = this.performanceChart.activeIndex;
      if (increment) {
        if (activeIndex + 1 == allDataPoints.length) {
          activeIndex = 0;
        } else {
          activeIndex++;
        }
      } else {
        if (activeIndex == 0) {
          activeIndex = allDataPoints.length - 1;
        } else {
          activeIndex--;
        }
      }
      this.performanceChart.activeIndex = activeIndex;
      let chartData = {
        labels: this.createDataPoints(
          this.allPerformanceDataPointsWithValues[activeIndex].data
        ).labels,
        datasets: [
          {
            ...chartStyleConfigs,
            data: this.createDataPoints(
              this.allPerformanceDataPointsWithValues[activeIndex].data
            ).data,
          },
        ],
      };
      this.performanceChart.chartData = chartData;
      this.performanceChart.timeStamp = allDataPoints[activeIndex].timestamp;
      this.performanceChart.evaluatorEmail =
        allDataPoints[activeIndex].evaluatorEmail;
      this.performanceChart.evaluatorType =
        allDataPoints[activeIndex].evaluatorType;
      this.performanceChart.extraOptions = chartConfigs.performanceChartOptions;
      this.performanceChart.gradientColors = config.colors.primaryGradient;
      this.performanceChart.gradientStops = [1, 0.4, 0];
      this.$refs.performanceChart.updateGradients(chartData);
    },
    addNewEvaluation(_, model) {
      const newEvaluation = model.performanceDataPointsWithValues;
      // Remove cummulative performance data points;
      if (this.allPerformanceDataPointsWithValues.length > 0) {
        const finalValuationIndex = this.allPerformanceDataPointsWithValues.findIndex((element) => element.evaluatorEmail == 'System');
        if (finalValuationIndex != -1) {
          this.allPerformanceDataPointsWithValues.splice(finalValuationIndex, 1);
        }
      }
      const allEvaluations = this.allPerformanceDataPointsWithValues;
      const newEvaluations = [];
      newEvaluations.push(...allEvaluations, ...newEvaluation);
      if (allEvaluations.length == 0) {
        this.performanceChart.evaluatorEmail = newEvaluation[0].evaluatorEmail;
        this.performanceChart.evaluatorType = newEvaluation[0].evaluatorType;
        this.performanceChart.chartData.datasets[0].data =
          this.createDataPoints(newEvaluation[0].data).data;
        this.performanceChart.timeStamp = newEvaluation[0].timestamp;
      }
      this.performanceValuesForUpdate = newEvaluations;
      this.$emit("on-evaluation", this.performanceValuesForUpdate);
      this.showModal = false;
    },
    onCloseModal() {
      this.$refs.addEvaluation.clear();
      this.showModal = false;
    },
    onShowModal() {
      this.$refs.addEvaluation.clear();
      this.showModal = true;
    },
    async onRemoveEvaluation() {
      // this.$refs.addEvaluation.clear();
      let activeIndex = this.performanceChart.activeIndex;
      const newEvaluations
        = this.allPerformanceDataPointsWithValues;
      const result = await swal.fire({
        title: "Are you sure?",
        text: `This will remove evaluation version ${activeIndex + 1}.`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-dark btn-fill ml-4",
          confirmButton: "btn btn-outline-danger",
        },
        confirmButtonText: "Remove evaluation",
        buttonsStyling: false,
      });
      if (result.isConfirmed) {
        newEvaluations.splice(activeIndex, 1);
        this.performanceValuesForUpdate = newEvaluations;
        this.$emit("on-evaluation", this.performanceValuesForUpdate, true);
      } else {
        return;
      }
    },
  },
  computed: {
    ...mapGetters({
      userType: "auth/type",
    }),
    isLastElement() {
      let activeIndex = this.performanceChart.activeIndex;
      let length = this.allPerformanceDataPointsWithValues.length;
      if (length === 0 || length === undefined) {
        return false;
      }
      return activeIndex + 1 === length;
    }
  },
};
</script>
