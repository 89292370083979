<template>
  <div class="startup-detail">
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div class="row" v-if="!isLoading">
      <!-- Top bar -->
      <div class="header col-12 mb-4 d-flex">
        <div class="col">
          <h3 class="card-title d-flex">
            <img class="avatar" src="img/placeholder.jpg" alt="..." />
            <span class="d-inline-block ml-2">
              <b>{{ startup.name }}</b>
              <router-link
                v-bind:to="{
                  name: 'Program-Detail',
                  params: { program_id: this.programId },
                }"
              >
                <span class="d-flex align-items-center">
                  <i class="tim-icons icon-link-72"></i>
                  <h5 class="color-primary my-0 ml-2">
                    {{ programDetails.name }} Program
                  </h5>
                </span>
              </router-link>
            </span>
          </h3>
        </div>
        <div class="col-auto p-0 d-flex flex-column">
          <router-link
            v-if="isSelf() || userType < 3"
            :to="{
              name: 'Startup-Edit',
              params: {
                program_id: this.programId,
                startup_id: this.startupId,
              },
            }"
          >
            <button
              class="btn btn-outline-info btn-sm w-100 mb-2 d-flex justify-content-around align-items-center"
            >
              <i class="lni lni-pencil-1 mr-2"></i>
              Edit
            </button>
          </router-link>
          <button
            v-if="userType < 3"
            class="btn btn-outline-danger btn-sm justify-content-around d-flex align-items-center"
            @click="removeStartup()"
          >
            <i class="lni lni-trash-3"></i>
            Remove
          </button>
        </div>
      </div>
      <!-- End top bar -->
      <!-- main dashboard -->
      <div class="col-md-9 col-12">
        <!-- Startup Info Card -->
        <card>
          <template slot="header">
            <h2 class="d-flex align-items-center mb-0">
              <i class="lni lni-bulb m-0"></i>
              <div class="col mt-2">
                <h3 class="card-title">Startup Information</h3>
                <h5 class="card-category">Click each drop down for details</h5>
              </div>
            </h2>
          </template>
          <collapse :multiple-active="true" :active-index="0">
            <!-- About Startup -->
            <collapse-item>
              <span slot="title" class="d-inline-flex">
                <span class="d-flex flex-row">
                  <i class="lni lni-rocket-5 mr-2"></i>
                  About Startup
                </span>

                <router-link
                  class="align-items-center d-flex"
                  v-if="isSelf()"
                  :to="{
                    name: 'Startup-Edit',
                    params: {
                      program_id: this.programId,
                      startup_id: this.startupId,
                    },
                  }"
                >
                  <div
                    class="d-flex col justify-content-around align-items-center"
                  >
                    Edit
                    <i class="lni lni-pencil ml-2"></i>
                  </div>
                </router-link>
              </span>
              <div>
                <strong>Description:</strong>
                <br />
                {{ startup.description }}
                <br />
                <br />
              </div>
              <table class="col-12">
                <tr></tr>
                <tr>
                  <td class="min-width">Sector:</td>
                  <td>
                    {{ startup.sector }}
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td class="min-width">Stage:</td>
                  <td>
                    {{ startup.stage }}
                  </td>
                </tr>
                <tr v-if="startup.website">
                  <td class="min-width">Website:</td>
                  <td>
                    {{ startup.website }}
                  </td>
                </tr>
                <tr v-if="startup.businessRegistrationDate">
                  <td class="min-width">Business Registration Date:</td>
                  <td>
                    {{
                      startup.businessRegistrationDate.toDate()
                        | moment("MMM, DD YYYY")
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="min-width">Annual Revenue(ETB):</td>
                  <td>
                    {{ startup.annualRevenue }}
                  </td>
                </tr>
                <br />
                <tr>
                  <td class="min-width">
                    <strong>Employees</strong>
                  </td>
                  <td>
                    {{
                      parseInt(startup.employees.female) +
                      parseInt(startup.employees.male)
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="min-width">Female:</td>
                  <td>
                    {{ startup.employees.female }}
                  </td>
                </tr>
                <tr>
                  <td class="min-width">Male</td>
                  <td>
                    {{ startup.employees.male }}
                  </td>
                </tr>
              </table>
            </collapse-item>
            <!-- Program Information -->
            <collapse-item>
              <span slot="title" class="d-inline-flex">
                <span class="d-flex flex-row">
                  <i class="lni lni-bricks mr-2"></i>
                  Program Information
                </span>
              </span>
              <table class="col-12">
                <tr>
                  <td class="min-width">Program Name:</td>
                  <td>
                    <a>
                      <router-link
                        v-bind:to="{
                          name: 'Program-Detail',
                          params: { program_id: this.programId },
                        }"
                      >
                        <b>{{ programDetails.name }}</b>
                      </router-link>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="min-width">Launch Date:</td>
                  <td>
                    {{ programDetails.start.toDate() | moment("MMM, DD YYYY") }}
                  </td>
                </tr>
                <tr>
                  <td class="min-width">End Date:</td>
                  <td>
                    <b>{{
                      programDetails.end.toDate() | moment("MMM, DD YYYY")
                    }}</b>
                  </td>
                </tr>
              </table>
            </collapse-item>
            <!-- Contact Information -->
            <collapse-item>
              <span slot="title" class="d-inline-flex align-items-center">
                <span class="d-flex flex-row">
                  <i class="lni lni-envelope-1 mr-2"></i>
                  Contact Information
                </span>

                <router-link
                  class="align-items-center d-flex"
                  v-if="isSelf()"
                  :to="{
                    name: 'Startup-Edit',
                    params: {
                      program_id: this.programId,
                      startup_id: this.startupId,
                    },
                  }"
                >
                  <div
                    class="d-flex col justify-content-around align-items-center"
                  >
                    Edit
                    <i class="lni lni-pencil ml-2"></i>
                  </div>
                </router-link>
              </span>
              <!-- Contact -->
              <table class="col-12">
                <tr class="min-width">
                  <strong>Contact</strong>
                </tr>
                <tr>
                  <td class="min-width">Phone:</td>
                  <td>
                    <a :href="`tel:${startup.contactInfo.phone}`">
                      {{ startup.contactInfo.phone }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="min-width">Email:</td>
                  <td>
                    <a :href="`mailto:${startup.contactInfo.email}`">
                      {{ startup.contactInfo.email }}
                    </a>
                  </td>
                </tr>
              </table>
              <br />
              <!-- Location -->
              <table class="col-12">
                <tr>
                  <strong>Location</strong>
                </tr>
                <tr>
                  <td class="min-width">Address:</td>
                  <td>{{ startup.contactInfo.address }}</td>
                </tr>
                <tr>
                  <td class="min-width">City:</td>
                  <td>{{ startup.contactInfo.city }}</td>
                </tr>
              </table>
            </collapse-item>
            <!-- Social Media -->
            <collapse-item v-if="startup.socialMediaLinks.length != 0">
              <span slot="title" class="d-inline-flex align-items-center">
                <span class="d-flex flex-row">
                  <i class="lni lni-comments-alt mr-2"></i>
                  Social Media
                </span>
                <router-link
                  class="align-items-center d-flex"
                  v-if="isSelf()"
                  :to="{
                    name: 'Startup-Edit',
                    params: {
                      program_id: this.programId,
                      startup_id: this.startupId,
                    },
                  }"
                >
                  <div
                    class="d-flex col justify-content-around align-items-center"
                  >
                    Edit
                    <i class="lni lni-pencil ml-2"></i>
                  </div>
                </router-link>
              </span>
              <table class="col-12">
                <tr
                  v-for="socialMedia in startup.socialMediaLinks"
                  :key="socialMedia"
                >
                  <td class="text-left">
                    <a target="blank" :href="socialMedia">
                      {{ socialMedia }}
                    </a>
                  </td>
                </tr>
              </table>
            </collapse-item>
            <!-- Additional Questions-->
            <collapse-item
              v-if="
                startup.additionalQuestionsWithAnswers.length != 0 &&
                programDetails.additionalQuestions.length != 0 &&
                (userType < 4 || isSelf())
              "
            >
              <span slot="title" class="d-inline-flex">
                <span class="d-flex flex-row">
                  <i class="tim-icons icon-alert-circle-exc mr-2"></i>
                  Additional Information
                </span>
                <router-link
                  class="align-items-center d-flex"
                  v-if="isSelf()"
                  :to="{
                    name: 'Startup-Edit',
                    params: {
                      program_id: this.programId,
                      startup_id: this.startupId,
                    },
                  }"
                >
                  <div
                    class="d-flex col justify-content-around align-items-center"
                  >
                    Edit
                    <i class="lni lni-pencil ml-2"></i>
                  </div>
                </router-link>
              </span>
              <div class="col-12 additonal-questions">
                <div
                  v-for="(value, index) in programDetails.additionalQuestions"
                  :key="index"
                >
                  <div class="text-left pb-3">
                    <strong>{{ value.question }}: </strong>
                    <br />
                    <p class="pt-2">
                      {{
                        startup.additionalQuestionsWithAnswers.get(value.id) ||
                        "--"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </collapse-item>
          </collapse>
        </card>
      </div>
      <!-- Stats Cards -->
      <div class="col-12 d-flex flex-column col-md-3 stats-cards">
        <div class="stats-card" v-for="card in statsCards" :key="card.title">
          <startup-stats-card
            :title="card.title"
            :sub-title="card.subTitle"
            :type="card.type"
            :icon="card.icon"
          >
          </startup-stats-card>
        </div>
      </div>
      <!-- Startup stats card -->
      <!-- Chart -->
      <div class="col-12" v-if="userType < 4 || isSelf()">
        <performance-chart
          ref="performanceChart"
          @on-evaluation="setEvaluations"
          :performanceDataPointsWithValues="
            startup.performanceDataPointsWithValues
          "
          :performanceDataPoints="programDetails.performanceDataPoints"
          :showEvaluatorDetail="userType < 4"
        ></performance-chart>
      </div>
      <!-- End main dashboard -->
      <!-- Timeline -->
      <div
        class="col-12"
        v-if="steps.length != 0 && (userType < 4 || isSelf())"
      >
        <div class="row">
          <div class="col-md-12" style="overflow-x: auto">
            <div class="swiper-container">
              <!-- <p class="swiper-control">
              <button type="button" class="btn btn-default btn-sm prev-slide">Prev</button>
              <button type="button" class="btn btn-default btn-sm next-slide">Next</button>
            </p> -->
              <div class="swiper-wrapper timeline d-block d-md-flex">
                <div
                  class="swiper-slide"
                  v-for="item in steps"
                  :key="item.title"
                >
                  <div class="timestamp">
                    <span class="date">
                      {{ item.dateLabel.toDate() | moment("MMM DD, YYYY") }}
                    </span>
                  </div>
                  <div class="status">
                    <span>{{ item.title }}</span>
                  </div>
                </div>
              </div>
              <!-- TODO: just change the left value below to change the position of the indicator dynamically -->
              <span
                class="now"
                v-bind:style="{
                  left: timelineCalc,
                  top: timelineCalc,
                }"
                >TO BE HERE</span
              >
              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Timeline End -->
      <div class="col-md-12 mar-top-big" v-if="userType < 4 || isSelf()">
        <card class="card-plain">
          <tabs type="primary" square>
            <tab-pane label="Program Milestones" v-if="steps.length != 0">
              <div class="row">
                <div class="card col-12">
                  <el-table :data="milestoneData">
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Name"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      label="Description"
                      property="description"
                    ></el-table-column>
                    <el-table-column
                      min-width="100"
                      sortable
                      label="Timeline"
                      property="workdays"
                    >
                      <div slot-scope="{ row }">
                        {{ row.workdays.toDate() | moment("MMM, DD YYYY") }}
                      </div>
                    </el-table-column>
                    <el-table-column
                      min-width="200"
                      align="center"
                      header-align="center"
                      label="Tags"
                    >
                      <div slot-scope="{ row }">
                        <div
                          v-for="tag in row.tags"
                          :key="tag.id"
                          class="but-margin"
                        >
                          <base-button type="danger" size="sm" simple>{{
                            tag
                          }}</base-button>
                        </div>
                      </div>
                    </el-table-column>
                    <el-table-column
                      min-width="200"
                      align="right"
                      header-align="right"
                      label="Actions"
                    >
                      <div slot-scope="{ row }">
                        <div v-if="isMileStoneComplete(row.id)">
                          Completed
                          <div
                            v-if="
                              row.isFileUpload &&
                              (userType < 4 || isSelf()) &&
                              getMilestoneDetails(row.id) != undefined
                            "
                          >
                            <a
                              target="blank"
                              :href="getMilestoneDetails(row.id).fileURL"
                            >
                              <button class="btn btn-outline-info mt-2 btn-sm">
                                View attachment
                              </button>
                            </a>
                          </div>
                          <div>
                            <button
                              v-if="userType < 3 && !row.isFileUpload"
                              class="btn btn-outline-danger mt-2 btn-sm"
                              @click="
                                setMilestoneCompletion(row.id, null, false)
                              "
                            >
                              Reset milestone
                            </button>
                            <button
                              v-if="
                                (userType < 3 || isSelf()) && row.isFileUpload
                              "
                              class="btn btn-outline-danger mt-2 btn-sm"
                              @click="
                                setMilestoneCompletion(row.id, null, false)
                              "
                            >
                              Remove attachment
                            </button>
                          </div>
                        </div>
                        <div v-if="!isMileStoneComplete(row.id)">
                          <div v-if="isSelf()">
                            <div v-if="row.isFileUpload">
                              <input
                                accept="image/*,.pdf"
                                @change="fileUploadChange"
                                type="file"
                                :id="row.id"
                                name="File"
                                class="btn btn-outline-default btn-sm w-100"
                                :multiple="false"
                                aria-invalid="false"
                              />
                              <span class="d-none"> </span>
                            </div>
                            <div v-if="!row.isFileUpload">
                              <button
                                class="btn btn-outline-info mt-2 btn-sm"
                                @click="setMilestoneCompletion(row.id)"
                              >
                                Mark as completed
                              </button>
                            </div>
                          </div>
                          <div v-if="!isSelf()">N/A</div>
                        </div>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse, CollapseItem, TabPane, Tabs } from "src/components";
import StartupStatsCard from "src/components/Cards/startupStatsCard";
import swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import performanceChart from "./performanceChart.vue";
export default {
  components: {
    TabPane,
    Tabs,
    StartupStatsCard,
    Collapse,
    CollapseItem,
    performanceChart,
  },
  computed: {
    ...mapGetters({
      userType: "auth/type",
      getStartup: "startup/getStartup",
      getProgramMilestones: "program/getProgramMilestones",
    }),
    bigLineChartCategories() {
      return [
        { name: "Initial", icon: "fas fa-calendar-minus2" },
        { name: "Now", icon: "fas fa-calendar-plus" },
      ];
    },
    timelineSize() {
      let segment = this.currentStage;
      let leng = this.steps.length;
      let diff = 200;
      let grandTotal = diff * leng;
      let x = diff * segment;
      let z = (x * 100) / grandTotal;
      return z;
    },
    timelineCalc() {
      return `calc(${this.timelineSize}%)`;
    },
  },
  data() {
    return {
      currentStage: null,
      currentUser: null,
      programDeadline: null,
      steps: [],
      startup: {},
      programDetails: null,
      statsCards: [
        {
          title: null,
          subTitle: "Days Left",
          type: "primary",
          icon: "lni lni-calendar-days",
          // footer: '<i class="tim-icons icon-refresh-01"></i> Update Now'
        },
        {
          title: null,
          subTitle: "Performance",
          type: "primary",
          icon: "lni lni-trend-up-1",
          // footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
        },
        {
          title: null,
          subTitle: "Final Score",
          type: "primary",
          icon: "lni lni-bar-chart-4",
          // footer: '<i class="tim-icons icon-sound-wave"></i></i> Last Research'
        },
      ],
      inputRes: null,
      completedMilestones: [],
      name: null,
      startupName: null,
      phone: null,
      type: null,
      email: null,
      address: null,
      city: null,
      website: null,
      valuation: null,
      initialCap: null,
      jobs: null,
      profits: null,
      stage: null,
      assets: null,
      startups: null,
      programLaunchDate: null,
      description: null,
      programId: null,
      startupId: null,
      images: {
        regular: null,
      },
      activeName: "first",
      milestoneData: [],
      actionData: [],
      productData: [],
      memberData: [],
      isLoading: true,
    };
  },
  async created() {
    this.fetchStartupData();
  },
  methods: {
    ...mapActions({
      setPerformanceData: "startup/setPerformanceData",
      getProgram: "program/getProgram",
      setStartupMilestones: "startup/setStartupMilestones",
      uploadFile: "startup/uploadFile",
      getCurrentUser: "user/getLoggedInUserData",
      cummulateStartupEvaluations: "startup/cummulateStartupEvaluations",
      calculateStartupPerformance: "startup/calculateStartupPerformance",
      meanStartupEvaluations: "startup/meanStartupEvaluations",
      deleteStartup: "startup/deleteStartup",
    }),

    async fetchStartupData() {
      this.programId = this.$route.params.program_id;
      this.startupId = this.$route.params.startup_id;
      this.currentUser = await this.getCurrentUser();
      if (this.$route.params.startup !== undefined) {
        this.startup = this.$route.params.startup;
        this.programDetails = this.$route.params.programDetails;
      } else if (
        this.$route.params.startup_id &&
        this.$route.params.program_id
      ) {
        this.startup = await this.getStartup(
          this.$route.params.program_id,
          this.$route.params.startup_id
        );
      } else if (
        this.currentUser != null &&
        this.currentUser.programs != null &&
        this.currentUser.programs.length > 0
      ) {
        this.programId = this.currentUser.programs[0];
        if (this.currentUser.userId != null) {
          this.startupId = this.currentUser.userId;
          this.startup = await this.getStartup(this.programId, this.startupId);
        } else {
          this.$router.push(`/startup/register/${this.programId}`);
        }
      }
      if (this.startup == null) {
        return;
      }
      this.completedMilestones = this.startup.completedMilestones;
      this.steps = [];
      await this.getProgramMilestones(this.programId).then((milestones) => {
        let i = 0;
        this.milestoneData = milestones;
        milestones.sort((a, b) => a.workdays - b.workdays);
        milestones.forEach((milestone) => {
          let data = {
            dateLabel: milestone.workdays,
            title: milestone.name,
          };
          this.steps.push(data);
          let stepDate = milestone.workdays.seconds * 1000;
          let date = new Date().getTime();
          if (stepDate < date) {
            i++;
          }
          this.currentStage = i;
        });
      });

      this.loadProgramData();
    },

    async loadProgramData() {
      return this.getProgram(this.programId).then(async (program) => {
        this.programDetails = program;
        let timeLeft =
          this.programDetails.end.toDate().getTime() - new Date().getTime();
        if (timeLeft > 0) {
          this.statsCards[0].title = `${Math.ceil(
            timeLeft / (1000 * 60 * 60 * 24)
          )}`;
        } else {
          // this.statsCards[0].title = '0';
        }
        if (
          this.startup.performanceDataPointsWithValues != undefined &&
          this.programDetails != undefined &&
          this.programDetails.performanceDataPoints != undefined
        ) {
          // Average score of all performance evaluations.
          let data = [];
          data = this.startup.performanceDataPointsWithValues;
          const dataPoints = this.programDetails.performanceDataPoints;
          let finalValuationIndex = data.findIndex(
            (element) => element.evaluatorEmail == "System"
          );
          if (finalValuationIndex != -1) {
            data.splice(finalValuationIndex, 1);
          }
          await this.cummulateStartupEvaluations({
            evaluations: data,
            labels: dataPoints,
          }).then(async (value) => {
            if (value === null) {
              return;
            }
            data.push(value);
            await this.calculateStartupPerformance({
              evaluationData: value.data,
            }).then((performance) => {
              this.statsCards[1].title = `${performance}%`;
            });
            await this.calculateStartupPerformance({
              evaluationData: value.data,
              percentage: false,
            }).then((performance) => {
              this.statsCards[2].title = `${performance}/5`;
            });
          });
          this.startup.performanceDataPointsWithValues = data;
        }
        // Ensure additional questions are a map, and are sorted by name.\

        if (
          this.programDetails.additionalQuestions != undefined &&
          this.programDetails.additionalQuestions.length > 0
        ) {
          this.startup.additionalQuestionsWithAnswers = new Map(
            Object.entries(this.startup.additionalQuestionsWithAnswers).sort()
          );

          this.programDetails.additionalQuestions.sort(function (a, b) {
            if (a.question == undefined || b.question == undefined) {
              return 0;
            }
            if (a.question < b.question) {
              return -1;
            }
            if (a.question > b.question) {
              return 1;
            }
            return 0;
          });
        }

        this.isLoading = false;
      });
    },

    isSelf() {
      return (
        this.userType === 4 &&
        this.currentUser != null &&
        this.currentUser.userId === this.startupId
      );
    },
    sort(values) {
      if (values == null) {
        return [];
      }
      if (values.length > 0) {
        var a = values.sort((a, b) => a - b);
        return a;
      } else {
        return [];
      }
    },
    isMileStoneComplete(milestoneId) {
      if (
        this.completedMilestones == null ||
        this.completedMilestones.length == 0
      ) {
        return false;
      }
      const milestones = this.completedMilestones.filter(
        (milestone) => milestone.id == milestoneId
      );
      if (milestones.length > 0) {
        return true;
      }
      return false;
    },
    getMilestoneDetails(milestoneId) {
      if (
        this.completedMilestones == null ||
        this.completedMilestones.length == 0
      ) {
        return false;
      }
      if (this.isMileStoneComplete(milestoneId)) {
        return this.completedMilestones.filter(
          (milestone) => milestone.id == milestoneId
        )[0];
      }
    },
    async fileUploadChange(event) {
      const file = event.target.files[0];
      if (file == null) {
        return;
      }
      try {
        const inputClassList = event.srcElement.classList.value;
        event.srcElement.setAttribute("disabled", true);
        event.srcElement.classList = ["d-none"];
        event.srcElement.nextSibling.classList = ["d-block btn btn-info"];
        const payload = {
          milestoneId: event.target.id,
          startupId: this.startupId,
          file: file,
        };
        const url = await this.uploadFile(payload);
        if (url) {
          await this.setMilestoneCompletion(event.target.id, url);
          event.srcElement.removeAttribute("disabled");
          event.srcElement.classList = [inputClassList];
        }
      } catch (error) {
        console.error(error);
        swal.fire({
          title: "Upload failed",
          text: "Unable to upload file, check your connection and try again",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false,
        });
      }
    },
    async setMilestoneCompletion(milestoneId, fileURL, completed) {
      try {
        const milestone = {
          id: milestoneId,
        };
        if (fileURL != null) {
          milestone.fileURL = fileURL;
        }
        if (completed == null || completed == true) {
          if (
            this.completedMilestones == null ||
            this.completedMilestones == undefined
          ) {
            this.completedMilestones = [];
          }
          this.completedMilestones.push(milestone);
        } else {
          if (
            this.completedMilestones == null ||
            this.completedMilestones == undefined
          ) {
            return;
          } else {
            const result = await swal.fire({
              title: "Are you sure?",
              text: `Please confirm you want to mark this item as incomplete. Startup will be required to answer this milestone again.`,
              icon: "warning",
              showCancelButton: true,
              customClass: {
                cancelButton: "btn btn-dark btn-fill ml-4",
                confirmButton: "btn btn-outline-danger",
              },
              confirmButtonText: "Reset milestone",
              buttonsStyling: false,
            });
            if (result.isConfirmed) {
              let index = this.completedMilestones.findIndex(
                (milestone) => milestone.id == milestoneId
              );
              this.completedMilestones.splice(index, 1);
            } else {
              return;
            }
          }
        }
        const payload = {
          programId: this.programId,
          startupId: this.startupId,
          completedMilestones: [],
        };
        payload.completedMilestones = this.completedMilestones;
        await this.setStartupMilestones(payload);
      } catch (error) {
        console.error(error);
      }
    },
    setEvaluations(allEvaluations, remove = false) {
      let payload = {
        startupId: this.startupId,
        programId: this.programId,
        performanceDataPointsWithValues: allEvaluations,
      };
      this.setPerformanceData(payload).then(async () => {
        await swal.fire({
          title: "Complete!",
          text: `You have successfuly ${
            remove ? "removed" : "added"
          } a new evaluation`,
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          buttonsStyling: false,
        });
        this.isLoading = true;
        await this.fetchStartupData();
        // this.$refs.performanceChart.loadData();
      });
    },

    removeStartup() {
      swal
        .fire({
          title: "Are you sure?",
          text: `Please confirm you want to delete the startup ${this.startup.name}. You cant revert once you have deleted the startup.`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Confirm",
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            // TODO: Delete Startup
            try {
              await this.deleteStartup({
                programId: this.programId,
                startupId: this.startupId,
              });
              swal.fire({
                title: "Completed!",
                text: "You have deleted the startup",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-success btn-fill",
                },
                buttonsStyling: false,
              });
              this.$router.push(`/programs/detail/${this.programId}`);
            } catch (error) {
              console.error(error);
              swal.fire({
                title: "Remove startup failed",
                text: "Unable to remove startup, please try again",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-info",
                },
                buttonsStyling: false,
              });
            }
          }
        });
    },
  },
};
</script>
<style lang="scss">
.startup-detail {
  .startup-detail-chart-btn {
    .btn.active {
      background-image: none !important;
      background-color: var(--primary) !important;
    }
  }

  .stats-cards {
    .stats-card {
      .card {
        background-image: linear-gradient(
          to bottom,
          rgb(86, 210, 238),
          rgb(222, 162, 249)
        );

        .info-icon {
          border-radius: 10px;
          background-color: rgb(86, 210, 238);
          background-image: none;
        }

        .numbers {
          p {
            color: white;
            margin-bottom: 0.5rem;
            font-size: 1rem;
          }

          h1 {
            font-weight: 600;
            opacity: 0.7;
          }
        }
      }

      &:nth-child(1) {
        .card {
          background-image: linear-gradient(
            to bottom,
            rgb(119, 175, 237),
            rgb(248, 214, 238)
          );

          .info-icon {
            background-color: rgb(119, 175, 237);
          }
        }
      }

      &:nth-child(2) {
        .card {
          background-image: linear-gradient(
            to bottom,
            rgb(247, 162, 151),
            rgb(255, 221, 155)
          );

          .info-icon {
            background-color: rgb(247, 162, 151);
          }
        }
      }
    }
  }

  .startup-info {
    .nav-link {
      background-image: none !important;
    }
  }

  .mar-small {
    margin-bottom: 5px !important;
  }

  .extended-forms .progress {
    margin-bottom: 30px;
  }

  .but-margin {
    margin-bottom: 6px;
  }

  .mar-left {
    margin-left: 10px;
  }

  td {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: right;
  }

  .min-width {
    text-align: left;
    width: 30%;
    white-space: nowrap;
  }

  .card {
    border-radius: 1.2rem !important;
  }

  .mar-bot-big {
    margin-bottom: 50px;
  }

  .mar-top-big {
    margin-top: 30px;
  }

  // Timeline
  body {
    background: #e8eeff;
  }

  // #app {
  //   padding: 50px 0;
  // }
  .cell {
    .expired {
      color: #ec250d;
      font-weight: bold;
    }
  }

  .swiper-container .timeline {
    margin: 20px 0;
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
    position: absolute;
    z-index: 3;
    top: 20px;
    // overflow-x: scroll;
    width: 100%;
    place-content: space-between;
    overflow: scroll;

    .swiper-slide {
      display: flex;
      max-width: 1fr;
       flex-direction: column;
      @media only screen and (max-width: 768px) {
        margin-left: 1.2rem;
        max-width: 80%;
      }
    }

    @media only screen and (max-width: 768px) {
      margin-left: 100px;
    }
  }

  .timeline li {
    transition: all 200ms ease-in;
  }

  .timestamp {
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
    .date {
      text-wrap: nowrap;
    }

    @media only screen and (max-width: 768px) {
      align-items: flex-start;
      margin-bottom: 0px;
    }
  }

  .status {
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    // border-image: linear-gradient(90deg, yellow, green) 1;
    // border-top: 4px solid;
    position: relative;
    transition: all 200ms ease-in;

    @media only screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  .swiper-container {
    padding-bottom: 2em;
    width: auto;
    overflow: hidden;
    position: relative;
    height: 200px;
    @media only screen and (max-width: 768px) {
      overflow-y: scroll;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 0.375rem;
      border-radius: 0.25rem;
      background-color: rgb(128 128 128 / 30%);
      position: absolute;
      top: calc(4em + 20px);

      @media only screen and (max-width: 768px) {
        top: 0;
        left: 110px;
        height: 100%;
        width: 0.375rem;
        // background-image: linear-gradient(
        //   to bottom,
        //   rgb(247, 181, 0),
        //   rgb(199, 0, 255),
        //   rgb(119, 175, 237)
        // );
        background-color: rgb(128 128 128 / 30%);
      }
    }

    .now {
      position: absolute;
      mix-blend-mode: multiply;
      font-size: 0.7em;
      padding: 2px 2em;
      color: white;
      width: 100px;
      background: grey;
      text-align: center;
      border-radius: 2em;
      z-index: 1;

      @media only screen and (max-width: 768px) {
        left: 0px !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-left: 0px;
      }

      @media only screen and (min-width: 768px) {
        top: calc(100% - 12.25rem) !important;
      }

      &::after {
        content: "";
        border-radius: 0.25rem;
        height: 0.375rem;
        width: 100vw;
        display: block;
        background-image: linear-gradient(
          to right,
          rgb(247, 181, 0),
          rgb(199, 0, 255),
          rgb(119, 175, 237)
        );
        position: absolute;
        top: calc(4rem + 8px);
        right: 50%;

        @media only screen and (max-width: 768px) {
          top: 10px;
          rotate: 90deg;
          width: 500px;
          transform-origin: right;
          right: -13%;
        }
      }

      &::before {
        content: "";
        bottom: -5px;
        z-index: -1;
        margin: auto;
        width: 15px;
        display: block;
        transform: translateX(-50%) rotate(45deg);
        height: 15px;
        position: absolute;
        left: 50%;
        background: grey;

        @media only screen and (max-width: 768px) {
          left: 100%;
          bottom: 1px;
        }
      }
    }
  }

  .additonal-questions {
    strong {
      font-size: 16px;
    }
  }

  .status span {
    font-weight: 600;
    padding-top: 20px;

    @media only screen and (max-width: 768px) {
      padding: 0px;
      padding-bottom: 20px;
      text-align: left;
    }
  }

  .status span:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: var(--primary);
    border-radius: 25px;
    border: 4px solid #fff;
    position: absolute;
    top: -15px;
    left: 42%;
    transition: all 200ms ease-in;

    @media only screen and (max-width: 768px) {
      left: -15px;
      top: 0;
    }
  }

  .swiper-control {
    text-align: right;
  }
}
</style>
