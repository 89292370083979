<template>
  <div>
    <card class="stacked-form" :title="'Invite ' + readableUserType">
      <ValidationObserver ref="form">
        <form @submit.prevent="validate">
          <div class="row justify-content-between">
            <div class="col-6">
              <div v-if="$route.params.program_id != null">
                Add user to program:
                <router-link v-bind:to="{
      name: 'Program-Detail',
      params: { program_id: $route.params.program_id },
    }">
                  {{ $route.params.program_id }}
                </router-link>
              </div>
              <h6>User Information</h6>
              <ValidationProvider name="Email" rules="required|min:3" v-slot="{ passed, failed, errors }">
                <base-input class="mt-4" required label="User Email" v-model="user.email" type="email"
                  placeholder="Enter User Email" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                <div class="d-flex flex-column pt-4">
                  <label for="type">User Type</label>
                  <el-select class="select-default" size="large" label="User Type" placeholder="Select User Type"
                    v-model="user.type" :error="errors[0]">
                    <el-option v-for="option in authTypesSelect" class="select-danger" :value="option.value"
                      :label="option.label" :key="option.id">
                    </el-option>
                  </el-select>
                  <label class="text-danger">{{ errors[0] }}</label>
                </div>
              </ValidationProvider>
              <base-button class="btn-block" type="danger" @click="createUser()" :disabled="isLoading">
                <i class="fas fa-plus" v-if="!isLoading"></i>
                <svg v-if="isLoading" width="20" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0" xml:space="preserve">
                  <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity: 0.5" />
                  <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6">
                    <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52"
                      repeatCount="indefinite" />
                  </circle>
                </svg>
                Invite user</base-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </card>
  </div>
</template>
<script>
import { Option, Select } from "element-ui";
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
extend("required", required);
extend("email", email);
extend("min", min);
export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      isLoading: true,
      programId: null,
      readableUserType: "User",
      authTypesSelect: [],
      user: {
        email: null,
        userId: null,
        programs: [],
        type: null,
      },
      credentials: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllUsers: "user/allUsers",
      deleteUser: "user/deleteUser",
      addUser: "user/addUser",
      createUserAuth: "auth/createUserAuth",
      sendToEmail: "auth/sendSignInLinkToEmail",
      userExists: "user/userExists",
      getUser: "user/getUser",
      addManagerToProgram: "program/addIncubationManager",
      addProgramToUser:"user/updateUserData",
      allowedAuthTypesSelect: "auth/allowedAuthTypesSelect",
    }),
    async createUser() {
      this.isLoading = true;
      this.programId = this.$route.params.program_id;
      if (this.programId) {
        this.user.programs.push(this.programId);
      }
      try {
        if (this.user.email == null || this.user.email == "") {
          throw "User email can't be empty";
        }
        let userId;
        let existingUser;
        await this.getUser(this.user.email).then((user) => existingUser = user).catch((error) =>
          console.log(error)
        );
        if (existingUser != null) {
          if (existingUser.type == "startup") {
            throw {
              message:
                "Startup already registered, please contact the administrator",
            };
          } else if (this.user.type != existingUser.type) {
            throw {
              message:
                "User already registered, please contact the administrator",
            };
          }
          else {
            userId = existingUser.id;
          }
        } else {
          userId = await this.addUser(this.user);
        }
        await this.sendToEmail(this.user.email);
        let payload = {
          programId: this.programId,
          userId: userId,
        };
        if (this.user.type == "incubation_manager" && this.programId != null) {
          await this.addManagerToProgram(payload);
          if(existingUser != null){
            existingUser.userId = existingUser.id;
            existingUser.programs.push(this.programId);
            await this.addProgramToUser(existingUser);
          }
        }
        this.isLoading = false;
        swal.fire({
          title: `Success!`,
          text: `You have succesfully invited the user ${this.user.email}. Please follow instructions in the email to finish your onboarding process.`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "success",
        });
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        swal.fire({
          title: "Error",
          text: `${error.message}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-info",
          },
          buttonsStyling: false,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
    }),
  },
  async mounted() {
    this.authTypesSelect = await this.allowedAuthTypesSelect();
    const typeFromUrl = this.$route.params.user_type;
    // 
    // 
    if (typeFromUrl != null) {
      const types = this.authTypesSelect.filter((a) => a.value == typeFromUrl);
      this.authTypesSelect = types;
      if (types.length > 0) {
        this.user.type = types[0].value;
        this.readableUserType = types[0].label;
      }
    }
    this.isLoading = false;
  },
};
</script>
