<template>
  <div class="login-content">
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading" class="row">
      <div class="col-md-6 col-sm-12 mr-auto login-left">
        <div class="row login-container">
          <div class="header">
            <img class="ice180-logo" src="img/logo.svg" alt="ice180" />
            <br /><br />
            <p class="card-description">
              Startups and SMEs tracking tool for startup support programs,
              incubators and accelerators.
            </p>
          </div>
          <div class="login">
            <h2 class="card-title">Log in</h2>
            <div>
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                <base-input required label="Email Address" v-model="email" type="email" placeholder="you@email.com"
                  addon-left-icon="lni lni-envelope-1" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
              <ValidationProvider name="password" rules="required|min:5" v-slot="{ passed, failed, errors }">
                <!-- addon-right-icon="lni lni-eye" -->
                <base-input required v-model="password" label="Password" placeholder="Password"
                  addon-left-icon="lni lni-locked-1" type="password" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input> 
              </ValidationProvider>
            </div>
            <div slot="footer" class="mar-top">
              <button v-if="loginButton == true" disabled="true" class="btn btn-primary w-100">
                <svg width="20" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0" xml:space="preserve">
                  <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity: 0.5" />
                  <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6">
                    <animateTransform attributeName="transform" dur="2s" type="rotate" from="0 50 48" to="360 50 52"
                      repeatCount="indefinite" />
                  </circle>
                </svg>
              </button>
              <button @click="login" v-if="loginButton == false" :disabled="!email || !password"
                class="btn btn-primary w-100">
                Login
              </button>
              <button @click="sendLinkToEmail(email)" :disabled="!email" class="btn mt-3 btn-outline-default w-100">
                Send sign in link to email
              </button>
            </div>
          </div>
          <div>Version: {{ this.applicationVersion }}</div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 login-right">
        <img src="img/login-animated.svg" width="100%" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { default as Swal, default as swal } from "sweetalert2";
import { extend } from "vee-validate";
import { email, min, required } from "vee-validate/dist/rules";
import { mapActions, mapGetters, mapState } from "vuex";
import { version } from "../../../package.json";
extend("email", email);
extend("min", min);
extend("required", required);
export default {
  data() {
    return {
      applicationVersion: version,
      currentUser: null,
      email: "",
      password: "",
      loginButton: false,
      isLoading: true,
    };
  },
  async mounted() {
    let router = this.$router;
    if (
      this.userCredential != null &&
      this.userCredential.user.email.length > 0
    ) {
      this.currentUser = await this.getCurrentUser();
      router.push("/dashboard");
      return;
    }
    try {
      this.isLoading = true;
      const loginLinkUser = await this.tryLogin();
      if (loginLinkUser) {
        this.currentUser = await this.getCurrentUser();
        const type = await this.type;
        if (type == null || this.currentUser == null) {
          throw { code: "User not found", message: "User not found" };
        }
        try {
          const hasPassword = await this.userSetPassword(this.currentUser.email);
          if (!hasPassword) {
            await this.sendPasswordReset(this.currentUser.email);
            swal.fire({
              title: "Please set your password",
              text: `An email has been sent to ${this.currentUser.email}, follow the instructions in the email to set your password.`,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-info",
              },
              buttonsStyling: false,
            });
          }
        } catch (error) {
          swal.fire({
            title: "Error",
            text: `${error}`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-info",
            },
            buttonsStyling: false,
          });
        }
        router.push("/dashboard");

      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.code != null && error.code.includes("invalid-action-code")) {
        await Swal.fire({
          title: `Email link has expired`,
          text: `Please request a new link`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
        router.push("/");
      }
      else {
        await Swal.fire({
          title: `Sign in failed`,
          text: `Please contact the administrator`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    }
  },
  computed: {
    ...mapState("auth", ["userCredential"]),
    ...mapGetters({ type: "auth/type" }),
  },
  methods: {
    ...mapActions({
      userlogin: "auth/userLogin",
      tryLogin: "auth/tryLoginLink",
      sendPasswordReset: "auth/sendPasswordReset",
      getCurrentUser: "user/getLoggedInUserData",
      userSetPassword: "user/userExists",
      sendSignInLinkToEmail: "auth/sendSignInLinkToEmail",
      checkUser: "user/userIsRegistered",
    }),
    async sendLinkToEmail(email) {
      try {
        const userExists = await this.checkUser(email);
        if (userExists) {
          await this.sendSignInLinkToEmail(email);
          Swal.fire({
            title: `Success`,
            text: `Sign in link has been sent to the email address ${email}`,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger btn-fill",
            },
            icon: "success",
          });
        } else {
          throw "The email address you entered is not registered, please contact your administrator.";
        }
      } catch (error) {
        await Swal.fire({
          title: `Could not send link to email`,
          text: `${error}`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "error",
        });
      }
    },
    async login() {
      this.loginButton = true;
      let router = this.$router;
      this.userlogin({
        email: this.email,
        password: this.password,
      })
        .then(() =>
          router.push("/dashboard")
        )
        .catch((err) => {
          this.loginButton = false;
          Swal.fire(err.message);
        });
    },
  },
};
</script>
<style lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.txt-black {
  color: #000 !important;
}

.login-content {
  height: 100vh;
  overflow: hidden;
  display: grid;

  .login-left {
    box-shadow: 0 0 10rem rgba(9, 25, 40, 0.1);

    .login-container {
      height: 100%;
      max-width: 50%;
      place-content: center;
      row-gap: 2em;
      margin: 0 auto;
      display: grid;

      @media only screen and (max-width: 768px) {
        max-width: 80%;
      }

      .ice180-logo {
        height: 4.5rem;
      }

      .lni-eye {
        color: grey;
      }

      .login-after {
        display: flex;
        justify-content: space-between;

        .form-check {
          margin-top: 0px;
        }
      }

      .login-btn {
        border-radius: 2em;
        border-top-right-radius: 0px;
        box-shadow: 0 0.5rem 1rem #ec230d41;
        transition: all 500ms;

        &:hover {
          background: rgb(9, 25, 40) !important;
        }

        &:disabled {
          background: rgb(9, 25, 40) !important;
          color: white;
        }
      }
    }
  }

  .login-right {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAACxJREFUKBVjYBgFwzcE/v//3wrEn0E0Ub6EKgZS/z8zEaWDgWESUN0XIJ4EABn/GOtiU2ETAAAAAElFTkSuQmCC");
    display: grid;
    place-items: center;
    background-color: rgba(9, 25, 40, 0.03125);

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
</style>
<style scoped>
.mar-top {
  margin-top: 50px;
}
</style>
