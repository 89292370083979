<template>
  <div>
    <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
    <div v-if="!isLoading">
      <div class="row m-0 align-items-center">
        <h1 class="mb-0">Users</h1>
        <div v-if="type < 3" class="col-md-3 col-sm-12 ml-auto">
          <router-link to="/users/add">
            <base-button class="btn-block" type="danger">
              <i class="fas fa-plus"></i>
              Add New user</base-button
            >
          </router-link>
        </div>
      </div>
      <br />
      <div class="card">
        <el-table :data="users">
          <el-table-column min-width="150" label="Email" prop="email">
          </el-table-column>
          <el-table-column min-width="150" label="Type" prop="type">
          </el-table-column>
          <el-table-column min-width="150" label="Program Id">
            <div class="link" slot-scope="{ row }">
              <router-link
                v-bind:to="{
                  name: 'Program-Detail',
                  params: { program_id: row.programId },
                }"
              >
                {{ row.programId }}
              </router-link>
            </div>
          </el-table-column>
          <el-table-column min-width="150" label="Actions">
            <div class="link" slot-scope="{ row }">
              <button
                class="btn btn-outline btn-danger"
                @click="removeUser(row)"
              >
                Delete User
              </button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
  
  },
  data() {
    return {
      isLoading: true,
      users: [],
    };
  },
  methods: {
    ...mapActions({
      fetchAllUsers: "user/allUsers",
      deleteUser: "user/deleteUser",
    }),
    async fetchData() {
      return this.fetchAllUsers().then((users) => {
        this.users = users;
        this.isLoading = false;
      });
    },
    async removeUser(user) {
      const result = await swal.fire({
        title: `Are you sure you want to delete the user "${user.email}"?`,
        text: "Once deleted, you will not be able to recover this user!",
        showCancelButton: true,
        icon: "question",
        customClass: {
          confirmButton: "btn btn-danger btn-fill mr-2",
          cancelButton: "btn btn-succsess btn-outline",
        },
        confirmButtonText: "Delete User",
        buttonsStyling: false,
      });
      if (result.value) {
        console.warn("User deleted");
        await this.deleteUser(user.id);
        swal.fire({
          title: `User Removed`,
          text: `You have succesfully deleted the user "${user.email}"`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger btn-fill",
          },
          icon: "info",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      type: "auth/type",
    }),
  },
  created() {
    this.fetchData();
  },
};
</script>
