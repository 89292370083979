export default {
  colors: {
    default: '#344675',
    primary: '#f24742',
    success: '#00ff00',
    dark: '#212529',
    info: '#1d8cf8',
    danger: '#fd5d93',
    orange: '#ff8a76',
    teal: '#00d6b4',
    primaryGradient: [
      'rgba(242, 71, 66, 0.25)',
      'rgba(242, 71, 66, 0.05)',
      'rgba(242, 71, 66,0)'
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)']
  }
};
