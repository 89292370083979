var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"startup-detail"},[(_vm.isLoading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"header col-12 mb-4 d-flex"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"card-title d-flex"},[_c('img',{staticClass:"avatar",attrs:{"src":"img/placeholder.jpg","alt":"..."}}),_c('span',{staticClass:"d-inline-block ml-2"},[_c('b',[_vm._v(_vm._s(_vm.startup.name))]),_c('router-link',{attrs:{"to":{
                name: 'Program-Detail',
                params: { program_id: this.programId },
              }}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"tim-icons icon-link-72"}),_c('h5',{staticClass:"color-primary my-0 ml-2"},[_vm._v(" "+_vm._s(_vm.programDetails.name)+" Program ")])])])],1)])]),_c('div',{staticClass:"col-auto p-0 d-flex flex-column"},[(_vm.isSelf() || _vm.userType < 3)?_c('router-link',{attrs:{"to":{
            name: 'Startup-Edit',
            params: {
              program_id: this.programId,
              startup_id: this.startupId,
            },
          }}},[_c('button',{staticClass:"btn btn-outline-info btn-sm w-100 mb-2 d-flex justify-content-around align-items-center"},[_c('i',{staticClass:"lni lni-pencil-1 mr-2"}),_vm._v(" Edit ")])]):_vm._e(),(_vm.userType < 3)?_c('button',{staticClass:"btn btn-outline-danger btn-sm justify-content-around d-flex align-items-center",on:{"click":function($event){return _vm.removeStartup()}}},[_c('i',{staticClass:"lni lni-trash-3"}),_vm._v(" Remove ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-9 col-12"},[_c('card',[_c('template',{slot:"header"},[_c('h2',{staticClass:"d-flex align-items-center mb-0"},[_c('i',{staticClass:"lni lni-bulb m-0"}),_c('div',{staticClass:"col mt-2"},[_c('h3',{staticClass:"card-title"},[_vm._v("Startup Information")]),_c('h5',{staticClass:"card-category"},[_vm._v("Click each drop down for details")])])])]),_c('collapse',{attrs:{"multiple-active":true,"active-index":0}},[_c('collapse-item',[_c('span',{staticClass:"d-inline-flex",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"lni lni-rocket-5 mr-2"}),_vm._v(" About Startup ")]),(_vm.isSelf())?_c('router-link',{staticClass:"align-items-center d-flex",attrs:{"to":{
                  name: 'Startup-Edit',
                  params: {
                    program_id: this.programId,
                    startup_id: this.startupId,
                  },
                }}},[_c('div',{staticClass:"d-flex col justify-content-around align-items-center"},[_vm._v(" Edit "),_c('i',{staticClass:"lni lni-pencil ml-2"})])]):_vm._e()],1),_c('div',[_c('strong',[_vm._v("Description:")]),_c('br'),_vm._v(" "+_vm._s(_vm.startup.description)+" "),_c('br'),_c('br')]),_c('table',{staticClass:"col-12"},[_c('tr'),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Sector:")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.sector)+" ")])]),_c('tr'),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Stage:")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.stage)+" ")])]),(_vm.startup.website)?_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Website:")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.website)+" ")])]):_vm._e(),(_vm.startup.businessRegistrationDate)?_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Business Registration Date:")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.startup.businessRegistrationDate.toDate(),"MMM, DD YYYY"))+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Annual Revenue(ETB):")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.annualRevenue)+" ")])]),_c('br'),_c('tr',[_c('td',{staticClass:"min-width"},[_c('strong',[_vm._v("Employees")])]),_c('td',[_vm._v(" "+_vm._s(parseInt(_vm.startup.employees.female) + parseInt(_vm.startup.employees.male))+" ")])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Female:")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.employees.female)+" ")])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Male")]),_c('td',[_vm._v(" "+_vm._s(_vm.startup.employees.male)+" ")])])])]),_c('collapse-item',[_c('span',{staticClass:"d-inline-flex",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"lni lni-bricks mr-2"}),_vm._v(" Program Information ")])]),_c('table',{staticClass:"col-12"},[_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Program Name:")]),_c('td',[_c('a',[_c('router-link',{attrs:{"to":{
                        name: 'Program-Detail',
                        params: { program_id: this.programId },
                      }}},[_c('b',[_vm._v(_vm._s(_vm.programDetails.name))])])],1)])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Launch Date:")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.programDetails.start.toDate(),"MMM, DD YYYY"))+" ")])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("End Date:")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm._f("moment")(_vm.programDetails.end.toDate(),"MMM, DD YYYY")))])])])])]),_c('collapse-item',[_c('span',{staticClass:"d-inline-flex align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"lni lni-envelope-1 mr-2"}),_vm._v(" Contact Information ")]),(_vm.isSelf())?_c('router-link',{staticClass:"align-items-center d-flex",attrs:{"to":{
                  name: 'Startup-Edit',
                  params: {
                    program_id: this.programId,
                    startup_id: this.startupId,
                  },
                }}},[_c('div',{staticClass:"d-flex col justify-content-around align-items-center"},[_vm._v(" Edit "),_c('i',{staticClass:"lni lni-pencil ml-2"})])]):_vm._e()],1),_c('table',{staticClass:"col-12"},[_c('tr',{staticClass:"min-width"},[_c('strong',[_vm._v("Contact")])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Phone:")]),_c('td',[_c('a',{attrs:{"href":`tel:${_vm.startup.contactInfo.phone}`}},[_vm._v(" "+_vm._s(_vm.startup.contactInfo.phone)+" ")])])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Email:")]),_c('td',[_c('a',{attrs:{"href":`mailto:${_vm.startup.contactInfo.email}`}},[_vm._v(" "+_vm._s(_vm.startup.contactInfo.email)+" ")])])])]),_c('br'),_c('table',{staticClass:"col-12"},[_c('tr',[_c('strong',[_vm._v("Location")])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("Address:")]),_c('td',[_vm._v(_vm._s(_vm.startup.contactInfo.address))])]),_c('tr',[_c('td',{staticClass:"min-width"},[_vm._v("City:")]),_c('td',[_vm._v(_vm._s(_vm.startup.contactInfo.city))])])])]),(_vm.startup.socialMediaLinks.length != 0)?_c('collapse-item',[_c('span',{staticClass:"d-inline-flex align-items-center",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"lni lni-comments-alt mr-2"}),_vm._v(" Social Media ")]),(_vm.isSelf())?_c('router-link',{staticClass:"align-items-center d-flex",attrs:{"to":{
                  name: 'Startup-Edit',
                  params: {
                    program_id: this.programId,
                    startup_id: this.startupId,
                  },
                }}},[_c('div',{staticClass:"d-flex col justify-content-around align-items-center"},[_vm._v(" Edit "),_c('i',{staticClass:"lni lni-pencil ml-2"})])]):_vm._e()],1),_c('table',{staticClass:"col-12"},_vm._l((_vm.startup.socialMediaLinks),function(socialMedia){return _c('tr',{key:socialMedia},[_c('td',{staticClass:"text-left"},[_c('a',{attrs:{"target":"blank","href":socialMedia}},[_vm._v(" "+_vm._s(socialMedia)+" ")])])])}),0)]):_vm._e(),(
              _vm.startup.additionalQuestionsWithAnswers.length != 0 &&
              _vm.programDetails.additionalQuestions.length != 0 &&
              (_vm.userType < 4 || _vm.isSelf())
            )?_c('collapse-item',[_c('span',{staticClass:"d-inline-flex",attrs:{"slot":"title"},slot:"title"},[_c('span',{staticClass:"d-flex flex-row"},[_c('i',{staticClass:"tim-icons icon-alert-circle-exc mr-2"}),_vm._v(" Additional Information ")]),(_vm.isSelf())?_c('router-link',{staticClass:"align-items-center d-flex",attrs:{"to":{
                  name: 'Startup-Edit',
                  params: {
                    program_id: this.programId,
                    startup_id: this.startupId,
                  },
                }}},[_c('div',{staticClass:"d-flex col justify-content-around align-items-center"},[_vm._v(" Edit "),_c('i',{staticClass:"lni lni-pencil ml-2"})])]):_vm._e()],1),_c('div',{staticClass:"col-12 additonal-questions"},_vm._l((_vm.programDetails.additionalQuestions),function(value,index){return _c('div',{key:index},[_c('div',{staticClass:"text-left pb-3"},[_c('strong',[_vm._v(_vm._s(value.question)+": ")]),_c('br'),_c('p',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.startup.additionalQuestionsWithAnswers.get(value.id) || "--")+" ")])])])}),0)]):_vm._e()],1)],2)],1),_c('div',{staticClass:"col-12 d-flex flex-column col-md-3 stats-cards"},_vm._l((_vm.statsCards),function(card){return _c('div',{key:card.title,staticClass:"stats-card"},[_c('startup-stats-card',{attrs:{"title":card.title,"sub-title":card.subTitle,"type":card.type,"icon":card.icon}})],1)}),0),(_vm.userType < 4 || _vm.isSelf())?_c('div',{staticClass:"col-12"},[_c('performance-chart',{ref:"performanceChart",attrs:{"performanceDataPointsWithValues":_vm.startup.performanceDataPointsWithValues,"performanceDataPoints":_vm.programDetails.performanceDataPoints,"showEvaluatorDetail":_vm.userType < 4},on:{"on-evaluation":_vm.setEvaluations}})],1):_vm._e(),(_vm.steps.length != 0 && (_vm.userType < 4 || _vm.isSelf()))?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12",staticStyle:{"overflow-x":"auto"}},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper timeline d-block d-md-flex"},_vm._l((_vm.steps),function(item){return _c('div',{key:item.title,staticClass:"swiper-slide"},[_c('div',{staticClass:"timestamp"},[_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateLabel.toDate(),"MMM DD, YYYY"))+" ")])]),_c('div',{staticClass:"status"},[_c('span',[_vm._v(_vm._s(item.title))])])])}),0),_c('span',{staticClass:"now",style:({
                left: _vm.timelineCalc,
                top: _vm.timelineCalc,
              })},[_vm._v("TO BE HERE")]),_c('div',{staticClass:"swiper-pagination"})])])])]):_vm._e(),(_vm.userType < 4 || _vm.isSelf())?_c('div',{staticClass:"col-md-12 mar-top-big"},[_c('card',{staticClass:"card-plain"},[_c('tabs',{attrs:{"type":"primary","square":""}},[(_vm.steps.length != 0)?_c('tab-pane',{attrs:{"label":"Program Milestones"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"card col-12"},[_c('el-table',{attrs:{"data":_vm.milestoneData}},[_c('el-table-column',{attrs:{"min-width":"150","sortable":"","label":"Name","property":"name"}}),_c('el-table-column',{attrs:{"min-width":"200","label":"Description","property":"description"}}),_c('el-table-column',{attrs:{"min-width":"100","sortable":"","label":"Timeline","property":"workdays"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(row.workdays.toDate(),"MMM, DD YYYY"))+" ")])}}],null,false,2521327093)}),_c('el-table-column',{attrs:{"min-width":"200","align":"center","header-align":"center","label":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{},_vm._l((row.tags),function(tag){return _c('div',{key:tag.id,staticClass:"but-margin"},[_c('base-button',{attrs:{"type":"danger","size":"sm","simple":""}},[_vm._v(_vm._s(tag))])],1)}),0)}}],null,false,1401853184)}),_c('el-table-column',{attrs:{"min-width":"200","align":"right","header-align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{},[(_vm.isMileStoneComplete(row.id))?_c('div',[_vm._v(" Completed "),(
                            row.isFileUpload &&
                            (_vm.userType < 4 || _vm.isSelf()) &&
                            _vm.getMilestoneDetails(row.id) != undefined
                          )?_c('div',[_c('a',{attrs:{"target":"blank","href":_vm.getMilestoneDetails(row.id).fileURL}},[_c('button',{staticClass:"btn btn-outline-info mt-2 btn-sm"},[_vm._v(" View attachment ")])])]):_vm._e(),_c('div',[(_vm.userType < 3 && !row.isFileUpload)?_c('button',{staticClass:"btn btn-outline-danger mt-2 btn-sm",on:{"click":function($event){return _vm.setMilestoneCompletion(row.id, null, false)}}},[_vm._v(" Reset milestone ")]):_vm._e(),(
                              (_vm.userType < 3 || _vm.isSelf()) && row.isFileUpload
                            )?_c('button',{staticClass:"btn btn-outline-danger mt-2 btn-sm",on:{"click":function($event){return _vm.setMilestoneCompletion(row.id, null, false)}}},[_vm._v(" Remove attachment ")]):_vm._e()])]):_vm._e(),(!_vm.isMileStoneComplete(row.id))?_c('div',[(_vm.isSelf())?_c('div',[(row.isFileUpload)?_c('div',[_c('input',{staticClass:"btn btn-outline-default btn-sm w-100",attrs:{"accept":"image/*,.pdf","type":"file","id":row.id,"name":"File","multiple":false,"aria-invalid":"false"},on:{"change":_vm.fileUploadChange}}),_c('span',{staticClass:"d-none"})]):_vm._e(),(!row.isFileUpload)?_c('div',[_c('button',{staticClass:"btn btn-outline-info mt-2 btn-sm",on:{"click":function($event){return _vm.setMilestoneCompletion(row.id)}}},[_vm._v(" Mark as completed ")])]):_vm._e()]):_vm._e(),(!_vm.isSelf())?_c('div',[_vm._v("N/A")]):_vm._e()]):_vm._e()])}}],null,false,2677878167)})],1)],1)])]):_vm._e()],1)],1)],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }